<template>
  <section>
    <div class="section-jobs section" id="jobs">
      <JobsHero />
      <div class="jobsList">
        <VacancyCard class="jobsList-card" v-for="vacancy in vacancies" :key="vacancy._id" :vacancy="vacancy" />
      </div>
    </div>
  </section>
</template>

<script>
  import JobsHero from "./jobsHero.vue";
  import VacancyCard from "./vacancyCard.vue";
  import { mapGetters } from "vuex";
  export default {
    components: { JobsHero, VacancyCard },
    data: () => {
      return {
        vacancies: {},
      };
    },
    metaInfo: function () {
      const { jobs = {} } = this.locale;
      const { title = "", description = "", keywords = "" } = jobs;

      return {
        title,
        meta: [
          {
            vmid: "description",
            name: "description",
            content: description,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: description,
          },
          {
            vmid: "robots",
            name: "robots",
            content: "index, follow",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: title,
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: keywords,
          },
        ],
      };
    },
    computed: {
      ...mapGetters(["getLocale", "newLocale"]),
      locale() {
        return this.newLocale;
      },
    },
    watch: {
      getLocale() {
        this.getVacancies();
      },
    },
    methods: {
      getVacancies: async function () {
        const host = `${process.env.VUE_APP_API_ADMIN}vacancy`;
        const { data } = await this.$api.get(host);
        this.vacancies = data.filter((vacancy) => vacancy.isShow).reverse();
      },
    },
    mounted() {
      this.getVacancies();
    },
  };
</script>

<style lang="scss">
  .section-jobs {
    padding: 0 $mpadding;
    padding-top: 70px;
  }
  .jobsList {
    min-height: 65vh;
    display: grid;
    gap: 25px;
    margin: 20px 0;
    &-card {
      width: 100%;
    }

    @media (min-width: $pocket_width) {
      grid-template-columns: repeat(2, auto);
      justify-content: center;
    }

    @media (min-width: $desktop_width) {
      grid-template-columns: 968px;
    }
  }
</style>
